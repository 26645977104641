import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export default function Addsports() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);

  const [formData, setFormData] = useState({
    title: "",
    matchId: "",
    description: "",
  });

  useEffect(() => {
    fetchData1();
  }, []);

  const fetchData1 = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}matches`;
      const response = await axios.get(url);
      setData(response.data.matches);
    } catch (error) {
      console.error("Error fetching events:", error);
      setError("Failed to fetch matches.");
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('matchId', formData.matchId);
    formDataToSend.append('description', formData.description);
    if (file) formDataToSend.append('video', file);
  
    try {
      const url = `${process.env.REACT_APP_Image_URL}uploadVideo`;
      const response = await axios.post(url, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Video uploaded successfully.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/Match-video");
          setFormData({
            title: "",
            description: "",
            matchId: "",
          });
          setFile(null);
        }
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to upload video. Please try again later.",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  };
  

  return (
    <>
      <Row className="page-titles mx-0">
        <Col className="p-md-0">
          <Breadcrumb>
            <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Add Match Video</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Container fluid>
        <div className="card">
          <div className="card-body">
            <div className="form-validation">
              <Form className="form-valide" onSubmit={handleSubmit}>
                <Row>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="matchId">
                        Match <span className="login-danger">*</span>
                      </label>
                      <select
                        className={`form-select `}
                        id="matchId"
                        name="matchId"
                        value={formData.matchId}
                        onChange={onInputChange}
                      >
                        <option value="">Select Match</option>
                        {data && data.map((match) => (
                          <option key={match._id} value={match._id}>
                            {match.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="title">
                        Video Title <span className="login-danger">*</span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="title"
                        name="title"
                        value={formData.title}
                        placeholder="Enter title"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="description">
                        Description <span className="login-danger">*</span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="description"
                        name="description"
                        value={formData.description}
                        placeholder="Enter description"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="video">
                        Upload Video <span className="login-danger">*</span>
                      </label>
                      <input
                        className={`form-control `}
                        type="file"
                        id="video"
                        name="video"
                        accept="video/*"
                        onChange={onFileChange}
                      />
                    </div>
                  </div>

                  <Col md={12} className="d-flex justify-content-end mt-3">
                    <Button type="submit" className="btn btn-primary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
