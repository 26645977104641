import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { MdDashboardCustomize,MdOutlineSportsEsports } from "react-icons/md";
import { FaListUl,FaPenFancy ,FaVideo} from "react-icons/fa";
import { GiTeamIdea , GiAmericanFootballPlayer ,GiTeamUpgrade,GiBabyfootPlayers} from "react-icons/gi";
import { GiTennisBall } from "react-icons/gi";

export default function Sidebar() {
 const sidebar = [
    {
        icon:<MdDashboardCustomize/>,
        name:'Dashboard',
        link:"/Dashboard"
    },
    {
        icon:<MdOutlineSportsEsports/>,
        name:'Sports',
        link:"/sports"
    },
    {
        icon:<FaListUl/>,
        name:'Series',
        link:"/series"
    },
    {
        icon:<GiTeamIdea/>,
        name:'Match',
        link:"/match"
    },
    {
        icon:<GiTeamUpgrade/>,
        name:'Team',
        link:"/team"
    },
    {
        icon:<GiAmericanFootballPlayer/>,
        name:'player',
        link:"/player"
    },
    
    // {
    //     icon:<GiTennisBall/>,
    //     name:'Add Ball By Ball',
    //     link:"/addballbyball"
    // },
    {
        icon:<FaPenFancy/>,
        name:'Fancy',
        link:"/fancy"
    },
    {
        icon:<FaVideo/>,
        name:'Match Video',
        link:"/Match-Video"
    },
 ]

  return (
    <div className="nk-sidebar">
      <div className="nk-nav-scroll">
        <ul className="metismenu" id="menu">
          {/* <li className="nav-label">Dashboard</li> */}
          {sidebar && sidebar.map((item) =>(
            
             <li>
                <Link to={item.link} className='d-flex gap-2'>
                    <span>{item.icon}</span>
                    <span>{item.name}</span>
                </Link>
             </li>
        )  ) }
          
        </ul>
      </div>
    </div>
  );
}
