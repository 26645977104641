import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export default function Addsports() {
  const navigate = useNavigate();
  const [matches, setMatches] = useState([]);
  const [teams, setTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [formData, setFormData] = useState({
    RunnerName: "",
    matchId: "",
    TeamId: "",
    LayPrice1: "",
    LaySize1: "",
    BackPrice1: "",
    BackSize1: "",
    GameStatus: "SUSPENDED", 
    MarkStatus: ""
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const matchesUrl = `${process.env.REACT_APP_API_URL}matches`;
      const teamsUrl = `${process.env.REACT_APP_API_URL}/teams`;

      const [matchesResponse, teamsResponse] = await Promise.all([
        axios.get(matchesUrl),
        axios.get(teamsUrl)
      ]);

      setMatches(matchesResponse.data);
      setTeams(teamsResponse.data);
      console.warn("teamsResponse.data",teamsResponse.data)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onMatchChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      matchId: value,
      TeamId: "" 
    });
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const url = `${process.env.REACT_APP_API_URL}/bets`;

      const response = await axios.post(url, formData);
      console.log("API Response:", response.data);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Fancy added successfully.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/fancy");

          setFormData({
            RunnerName: "",
            matchId: "",
            TeamId: "",
            LayPrice1: "",
            LaySize1: "",
            BackPrice1: "",
            BackSize1: "",
            GameStatus: "SUSPENDED",
            MarkStatus: ""
          });
        }
      });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to add Fancy. Please try again later.",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <Row className="page-titles mx-0">
        <Col className="p-md-0">
          <Breadcrumb>
            <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Add Fancy</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Container fluid>
        <div className="card">
          <div className="card-body">
            <div className="form-validation">
              <Form className="form-valide" onSubmit={handleSubmit}>
                <Row>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="matchId">
                        Match <span className="login-danger">*</span>
                      </label>
                      <select
                        className={`form-select `}
                        id="matchId"
                        name="matchId"
                        value={formData.matchId}
                        onChange={onMatchChange}
                      >
                        <option value="">Select Match</option>
                        {matches.map((match) => (
                          <option key={match._id} value={match._id}>
                            {match.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="RunnerName">
                        Runner Name <span className="login-danger">*</span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="RunnerName"
                        name="RunnerName"
                        value={formData.RunnerName}
                        placeholder="Enter Runner Name"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="TeamId">
                        Team Name <span className="login-danger"></span>
                      </label>
                      <select
                        className={`form-select `}
                        id="TeamId"
                        name="TeamId"
                        value={formData.TeamId}
                        onChange={onInputChange}
                      >
                        <option value="">Select Team</option>
                        {teams && teams.map((team) => (
                          <option key={team._id} value={team._id}>
                            {team.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="LayPrice1">
                        Lay Price <span className="login-danger">*</span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="LayPrice1"
                        name="LayPrice1"
                        value={formData.LayPrice1}
                        placeholder="Enter Lay Price"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="LaySize1">
                        Lay Size <span className="login-danger">*</span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="LaySize1"
                        name="LaySize1"
                        value={formData.LaySize1}
                        placeholder="Enter Lay Size"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="BackPrice1">
                        Back Price <span className="login-danger">*</span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="BackPrice1"
                        name="BackPrice1"
                        value={formData.BackPrice1}
                        placeholder="Enter Back Price"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="BackSize1">
                        Back Size <span className="login-danger">*</span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="BackSize1"
                        name="BackSize1"
                        value={formData.BackSize1}
                        placeholder="Enter Back Size"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="GameStatus">
                        Game Status
                      </label>
                      <select
                        className={`form-select `}
                        id="GameStatus"
                        name="GameStatus"
                        value={formData.GameStatus}
                        onChange={onInputChange}
                      >
                        <option value="SUSPENDED">SUSPENDED</option>
                        <option value="Ball Running">Ball Running</option>
                     
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="MarkStatus">
                        Mark Status
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="MarkStatus"
                        name="MarkStatus"
                        value={formData.MarkStatus}
                        placeholder="Enter Mark Status"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <Col md={12} className="d-flex justify-content-end mt-3">
                    <Button type="submit" className="btn btn-primary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
