import React, { useState ,useEffect} from "react";
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export default function Addsports() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData1();

  }, []);
  const fetchData1 = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}sports`;
      const response = await axios.get(url);
      console.warn(response.data)
      setData(response.data.sports);


    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  const [formData, setFormData] = useState({
    name: "",
    sport_id: "" ,  
    countryCode: ""   
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const url = `${process.env.REACT_APP_API_URL}/teams`;

      const requestData = {
        name: formData.name,
        sport_id: formData.sport_id,
        countryCode: formData.countryCode
      };

      const response = await axios.post(url, requestData);
      console.log("API Response:", response.data);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Team added successfully.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/team");

          setFormData({
            name: "",
            sport_id: "",
            countryCode: ""
            
          });
        }
      });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to add Teams. Please try again later.",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
     
      });
    }
  };

  return (
    <>
      <Row className="page-titles mx-0">
        <Col className="p-md-0">
          <Breadcrumb>
            <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Add Team</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Container fluid>
        <div className="card">
          <div className="card-body">
            <div className="form-validation">
              <Form className="form-valide" onSubmit={handleSubmit}>
                <Row>
                <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="sport_id">
                Sports <span className="login-danger"></span>
                </label>
                <select
                  className={`form-select `}
                  id="sport_id"
                  name="sport_id"
                  value={formData.sport_id}
                  onChange={onInputChange}
                >
               <option value="">Select Sports</option>
                  {data && data.map((Sports) => (
                    <option key={Sports._id} value={Sports._id}>
                      {Sports.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="name">
                        Team Name <span className="login-danger">*</span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        placeholder="Enter Team Name"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="countryCode">
                      Country Code <span className="login-danger"></span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="countryCode"
                        name="countryCode"
                        value={formData.countryCode}
                        placeholder="Enter Country Code"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
              

                  <Col md={12} className="d-flex justify-content-end mt-3">
                    <Button type="submit" className="btn btn-primary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
