import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Login from "./Navbar/Login";
import Sidebar from "./Sidebar/Sidebar";
import Dashboard from "./Pages/Dashboard";
import Addballbyball from "./Pages/Dashboard";
import Fancy from "./Pages/Fancy";
import Addfancy from "./Pages/Addfancy";
import Match from "./Pages/Match";
import Addmatch from "./Pages/Addmatch";
import AddEditmatch from "./Pages/AddEditmatch";
import Player from "./Pages/Player";
import Addplayer from "./Pages/Addplayer";
import Series from "./Pages/Series";
import Sports from "./Pages/Sports";
import Team from "./Pages/Team";
import Addteams from "./Pages/Addteams";
import Addsports from './Pages/Addsports'
import Addseries from './Pages/Addseries'
import MatchVideo from './Pages/MatchVideo'
import AddVideo from './Pages/AddVideo'
function App() {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/*"
            element={
              <div id="main-wrapper">
                <Navbar />
                <Sidebar />
                <div className="content-body">
                  <div className="container-fluid mt-3">
                    <Routes>
                      <Route path="/Dashboard" element={<Dashboard />} />
                      <Route
                        path="/addballbyball"
                        element={<Addballbyball />}
                      />
                      <Route path="/fancy" element={<Fancy />} />
                      <Route path="/addfancy" element={<Addfancy />} />
                      <Route path="/match" element={<Match />} />
                      <Route path="/addmatch" element={<Addmatch />} />
                      <Route path="/addsports" element={<Addsports />} />
                      <Route path="/player" element={<Player />} />
                      <Route path="/series" element={<Series />} />
                      <Route path="/addseries" element={<Addseries />} />
                      <Route path="/sports" element={<Sports />} />
                      <Route path="/team" element={<Team />} />
                      <Route path="/addteams" element={<Addteams />} />
                      <Route path="/UpadateRuns" element={<AddEditmatch />} />
                      <Route path="/addplayer" element={<Addplayer />} />
                      <Route path="/Match-Video" element={<MatchVideo />} />
                      <Route path="/Add-Video" element={<AddVideo />} />
                    </Routes>
                  </div>
                </div>
              </div>
            }
          />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
