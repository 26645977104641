
import React, { useState, useEffect } from 'react'
import { Card, Table, Button, Form, Modal, Row, Col,Badge  } from "react-bootstrap";
import {FaRunning, FaTrophy, FaPlayCircle, FaCalendarAlt, FaUsers, FaUser, FaStar } from "react-icons/fa";

import axios from 'axios';
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEye } from "react-icons/fa6";
import { IoMdAdd } from "react-icons/io";
import { Link } from 'react-router-dom'
import { format } from 'date-fns';
import Swal from "sweetalert2";
export default function Player() {
  const [data1, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [showColumn_id, setShowColumn_id] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    // fetchData2()
  };
  useEffect(() => {
    fetchData1();

  }, []);
  const fetchData1 = async () => {
    try {
      const url = `${process.env.REACT_APP_Image_URL}videos`;
      const response = await axios.get(url);
      console.warn(response.data)
      setData(response.data);

    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  const handleUpdateSports = (_id) => {
    setShowColumn_id(_id);
    // alert(_id)
    fetchData2(_id)
   
  };
  const fetchData2 = async (_id) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}getPlayerById/${_id}`;
      const response = await axios.get(url);
      setFormData(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
      // setError("Error fetching data");
    }
  };
  const [formData, setFormData] = useState({
    name: "",
    role: "",
    // slug: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const seriesUpdate = async () => {
    const url = `${process.env.REACT_APP_API_URL}updatePlayer/${showColumn_id}`;
    const requestBody = {
      name: formData.name,
      role: formData.role,
      // slug: formData.slug,
 
    };

    try {
      const response = await axios.put(url, requestBody);
      Swal.fire({
        icon: "success",
        title: "Player updated",
        text: "Player updated successfully!",
      });
      handleClose();
      fetchData1();
    } catch (error) {
      console.error("Error adding Player:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Cannot updated Player !",
      });
      handleClose();
    }
  };
  const getFilteredData = () => {
    return data1.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
  };

  const getPaginatedData = () => {
    const filteredData = getFilteredData();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(getFilteredData().length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const Delete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
  
      if (result.isConfirmed) {
        const config = {
          method: "delete",
          url: `${process.env.REACT_APP_Image_URL}videos/${id}`,
        };
  
        const response = await axios(config);
        const data = response.data.status;
      
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            timer: 1500
          });
  
          fetchData1();
        
        
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleStatusChange = async (_id, status) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change status!",
      });
  
      if (result.isConfirmed) {
        const requestBody = {
          _id: _id,
          status: status === 1 ? 0 : 1,
        };
  
        const response = await axios.post(`${process.env.REACT_APP_API_URL}VideoStatus`, requestBody);
  
        const data = response.data.status;
      console.warn("response.data",data)
        if (data === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            timer: 1500,
          });
  
          fetchData1();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            timer: 2000,
          });
        }
      }
    } catch (error) {
      console.error("Error changing status:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "There was an error changing the status.",
        timer: 2000,
      });
    }
  };
  return (
    <>
    <div className="row">
    <div className="col-lg-4 col-sm-6">
    <Link to="/Match-Video" className="stretched-link no-underline">
        <div className="card gradient-1">
            <div className="card-body">
                <h3 className="card-title text-white">Total </h3>
                <div className="d-inline-block">
                    <h2 className="text-white">{data1.totalCount || 0}</h2>
                </div>
                <span className="float-right display-5 opacity-5">
                    <FaPlayCircle />
                </span>
            </div>
        </div>
        </Link>
    </div>
    <div className="col-lg-4 col-sm-6">
    <Link to="/Match-Video" className="stretched-link no-underline">
        <div className="card gradient-2">
            <div className="card-body">
                <h3 className="card-title text-white">Active </h3>
                <div className="d-inline-block">
                    <h2 className="text-white">{data1.totalActive || 0}</h2>
                </div>
                <span className="float-right display-5 opacity-5">
                <FaPlayCircle />
                </span>
            </div>
        </div>
        </Link>
    </div>
    <div className="col-lg-4 col-sm-6">
    <Link to="/Match-Video" className="stretched-link no-underline">
        <div className="card gradient-3">
            <div className="card-body">
                <h3 className="card-title text-white"> InActive </h3>
                <div className="d-inline-block">
                    <h2 className="text-white">{data1.totalInactive || 0}</h2>
                </div>
                <span className="float-right display-5 opacity-5">
                <FaPlayCircle />
                </span>
            </div>
        </div>
        </Link>
    </div>
    

</div>
    <div className='sports'>
    <Card>
    <Card.Header className="header_bg d-flex justify-content-between">
        <Card.Title>Match Video List</Card.Title>
        <Link to="/Add-Video"  className='text-dark'  style={{ textDecoration: 'none'}}  > <Button variant="success">   <IoMdAdd />Add New</Button></Link>
      </Card.Header>
      <Card.Body className="p-0">
      <div className="p-3 col-3">
            <Form.Control
              type="text"
              placeholder="Search Player..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="mb-3"
            />
          </div>
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Video</th>
                <th>Title</th>
                <th>Status</th>
            
                {/* <th>Country Code
                </th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {data1 && getPaginatedData().map((data,index) => (
                <tr key={index}>

              <td>  <td><strong>{(currentPage - 1) * itemsPerPage + index + 1}</strong></td></td>
                          <td>
              <video width="70" src={`https://virtualgameapi.a2logicgroup.com/${data.videoPath}`} muted></video>
            </td>
              <td className='capitalize'><strong>{data.title}</strong></td>
              <td className='capitalize'><strong>                   
                      <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch"
                 id={`flexSwitchCheckDefault-${data._id}`}
                 checked={data.status === 1}
                 onChange={() => handleStatusChange(data._id, data.status)}
             
                 />
              </div></strong></td>
                  {/* <td><strong>{data.role}</strong></td> */}
                  {/* <td><strong>{data.countryCode}</strong></td> */}
                  <td>
                    <div className="d-flex gap-2">
                      {/* <Link to="/addsports" className="text-success"> <IoMdAdd /></Link> */}
                      {/* <Link to="/" className="text-primary"> <FaEye /></Link> */}
                      {/* <div onClick={handleShow}> */}
                      <div >
                        <Link  onClick={() => handleUpdateSports(data._id)} className="text-secondary"> <FaEdit /></Link>
                        </div>
                      <Link  className="text-danger"    onClick={() => Delete(data._id)}> <RiDeleteBin6Line /></Link>

                    </div>
                  </td>
                </tr>
              ))
              }


            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between p-3">
            <div>Showing {getPaginatedData().length} of {getFilteredData().length} results</div>
            <div>
              {Array.from({ length: totalPages }, (_, i) => (
                <Button
                  key={i}
                  onClick={() => handlePageChange(i + 1)}
                  disabled={i + 1 === currentPage}
                  className="me-2"
                >
                  {i + 1}
                </Button>
              ))}
            </div>
          </div>
      </Card.Body>
    </Card>
    <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-light">
          <Modal.Title>Update Team</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <Form>
            <div className="d-flex jistify-content-start row">
            <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="name">
                      Team Name <span className="login-danger">*</span>
                      </label>
                      <input
                        className={`form-control `}
                        id="name"
                        name="name"
                        type="text"
                        value={formData.name}
                        placeholder="Enter Series Name"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="role">
                         Role <span className="login-danger"></span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="role"
                        name="role"
                        value={formData.role}
                        placeholder="Enter Role"
                        onChange={onInputChange}
                        // readOnly
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="slug">
                        Slug <span className="login-danger"></span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="slug"
                        name="slug"
                        value={formData.slug}
                        placeholder="Enter Slug"
                        onChange={onInputChange}
                      />
                    </div>
                  </div> */}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Form.Group
            className="d-flex justify-content-end"
            controlId="exampleForm.ControlInput3"
          >
            <Button className="btn btn-success mx-2" onClick={seriesUpdate}>
              <span>Submit</span>
            </Button>
          </Form.Group>
        </Modal.Footer>
      </Modal>
  </div>
  </>
  )
}
