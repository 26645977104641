import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Breadcrumb, Badge } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import Series from './Series';
import { GrStatusGood } from "react-icons/gr";

export default function AddBallByBallRun() {
  const match_id = localStorage.getItem("match_id");
  const team1_id = localStorage.getItem("team1_id");
  const team2_id = localStorage.getItem("team2_id");
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    matchId: match_id,
    teamId: team1_id,
    overNumber: "",
    ballNumber: "",
    runs: "",
    GameStatus: "",
    isWicket: false
  });
  const [matchData, setMatchData] = useState(null);
  const [team, setTeam] = useState('team1');

  useEffect(() => {
    fetchMatchData();
  }, []);

  const fetchMatchData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}matches/${match_id}`;
      const response = await axios.get(url);
      setMatchData(response.data);
      setFormData({
        ...formData,
        teamId: team === 'team1' ? team1_id : team2_id
      });
    } catch (error) {
      console.error("Error fetching match data:", error);
    }
  };

  const onInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleTeamChange = (team) => {
    setTeam(team);
    setFormData({
      ...formData,
      teamId: team === 'team1' ? team1_id : team2_id
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        matchId: formData.matchId,
        teamId: formData.teamId,
        overNumber: Number(formData.overNumber),
        ballNumber: Number(formData.ballNumber),
        runs: Number(formData.runs),
        isWicket: formData.isWicket,
        GameStatus: formData.GameStatus
      };
      const url = `${process.env.REACT_APP_API_URL}addBallByBallRun`;
      await axios.post(url, requestData);

      fetchMatchData();
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to update ball-by-ball runs. Please try again later.",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  };
  const updateGameStatus = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        matchId: formData.matchId,
        GameStatus: formData.GameStatus
      };
      const url = `${process.env.REACT_APP_API_URL}updateGameeStatus`;
      await axios.post(url, requestData);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Update Gamee Status!",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
      fetchMatchData();
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to update game status. Please try again later.",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  };

  const scoreColor = (score) => {
    if (score.runs === 0) return 'text-danger';
    if (score.wickets > 0) return 'text-warning';
    return 'text-success';
  };

  const statusColor = (status) => {
    switch (status) {
      case 'live':
        return 'bg-danger text-white';
      case 'upcoming':
        return 'bg-success text-white';
      case 'complete':
        return 'bg-primary text-white';
      default:
        return 'bg-secondary text-white';
    }
  };

  return (
    <>
      <Row className="page-titles mx-0">
        <Col className="p-md-0">
          <Breadcrumb>
            <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Add Ball-by-Ball Run</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Container fluid>
        <div className="card">
          <div className="card-body p-0">
        

            {matchData && (
              <>
                <section className="event-details">
                <div className="event-info">
                  <div className="event-intro">
                    <div className="intro mb-0">
                      {/* Team-1 Logo and Name */}
                      <div className="team-logo d-flex align-items-center gap-2">
                     <div>
                     <img src="https://falcon-html.asdesignsgalaxy.com/assets/images/Cricket-Teams/Falcon.jpg" alt="logo" />
                     <h3 className='capitalize'>{matchData.teams[0].name}</h3>
                     </div>
                       <h4 className="text-light"> {matchData.scores[0].runs} / {matchData.scores[0].wickets} ({matchData.scores[0].overs})</h4>
                      </div>
                  
                   
                      <h5>VS <br>
                      </br>
                      <h6><Badge className={`status-badge ${statusColor(matchData.is_inplay)}`}>
                      {matchData.is_inplay.charAt(0).toUpperCase() + matchData.is_inplay.slice(1)}
                    </Badge></h6></h5>
                      {/* Team-2 Logo and Name */}
                      <div className="team-logo d-flex align-items-center gap-2">
                      <h4 className="text-light">{matchData.scores[1].runs} / {matchData.scores[1].wickets} ({matchData.scores[1].overs})</h4>
                        <div>
                        <img src="https://falcon-html.asdesignsgalaxy.com/assets/images/Cricket-Teams/Tigers.jpg" alt="logo" />
                        <h3 className='capitalize'>{matchData.teams[1].name}</h3>
                        </div>
                       
                      </div>
                    </div>
                    <div className="event-content">
                      <div className="time capitalize">Series Name : {matchData.series_Name}</div>{" "}
                      {/* Event Date and Time */}
                      <div className="venue capitalize">{matchData.name}</div>{" "}
                      {/* Event Venue */}
                    </div>
                  </div>
                  </div>
                 
              </section>
             
              </>
            )}
            <Form className="form-valide p-3" onSubmit={handleSubmit}>
              <Row>
                <div className="col-md-6 form_details mt-3">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="team">
                      Select Team <span className="login-danger">*</span>
                    </label>
                    <Form.Control
                      as="select"
                      value={team}
                      className={`form-select`}
                      onChange={(e) => handleTeamChange(e.target.value)}
                    >
                      <option value="team1">{matchData && matchData.teams && matchData.teams[0].name}</option>
                      <option value="team2">{matchData && matchData.teams && matchData.teams[1].name}</option>
                    </Form.Control>
                  </div>
                </div>
                <div className="col-md-6 form_details mt-3">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="overNumber">
                      Over Number <span className="login-danger">*</span>
                    </label>
                    <Form.Control
                      as="select"
                      id="overNumber"
                        className="form-select"
                      name="overNumber"
                      value={formData.overNumber}
                      onChange={onInputChange}
                    >
                      {[...Array(20).keys()].map(i => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </div>
                <div className="col-md-6 form_details mt-3">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="ballNumber">
                      Ball Number <span className="login-danger">*</span>
                    </label>
                    <Form.Control
                      as="select"
                      id="ballNumber"
                        className="form-select"
                      name="ballNumber"
                      value={formData.ballNumber}
                      onChange={onInputChange}
                    >
                      {[...Array(6).keys()].map(i => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </div>
                <div className="col-md-6 form_details mt-3">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="runs">
                      Runs <span className="login-danger">*</span>
                    </label>
                    <Form.Control
                      as="select"
                      id="runs"
                      className="form-select"
                      name="runs"
                      value={formData.runs}

                      onChange={onInputChange}
                    >
                      {[0, 1, 2, 3, 4, 6].map(run => (
                        <option key={run} value={run}>
                          {run}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </div>
             
                <div className="col-md-6 form_details mt-3 ">
                  <Form.Group controlId="isWicket">
                    <Form.Check
                      type="checkbox"
                      label="Is Wicket"
                      name="isWicket"
                      checked={formData.isWicket}
                      onChange={onInputChange}
                    />
                  </Form.Group>
                </div>
                <Col md={12} className="d-flex justify-content-end mt-3">
                  <Link to="/match">
                    <Button type="button" className="btn btn-dark">
                      Back
                    </Button>
                  </Link> 
                  <Button type="submit" className="btn btn-primary mx-1">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
            <Form className="form-valide p-3 mt-4" onSubmit={updateGameStatus}>
              <Row className="align-items-baseline" >
                <div className="col-md-3 form_details mt-3">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="GameStatus">
                      Update Game Status <span className="login-danger">*</span>
                    </label>
                    <Form.Control
                      as="select"
                      id="GameStatus"
                      name="GameStatus"
                      value={formData.GameStatus}
                      onChange={onInputChange}
                      className="form-select"
                    >
                      <option value="">Select Status</option>
                      <option value="SUSPENDED">SUSPENDED</option>
                      <option value="Ball Running">Ball Running</option>
                    </Form.Control>
                  </div>
                </div>
                <div className="col-md-3 ">
                <Button  variant="primary" type="submit">
                 <GrStatusGood />
                </Button>
                </div>
              
              </Row>
            </Form>
          </div>
        </div>
      </Container>
    </>
  );
}
