import React, { useState, useEffect } from 'react';
import { Card, Table, ProgressBar, Button } from 'react-bootstrap';
import axios from 'axios';
import { FaEdit, FaRunning, FaTrophy, FaPlayCircle, FaCalendarAlt, FaUsers, FaUser, FaStar } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEye } from "react-icons/fa6";
import { IoMdAdd } from "react-icons/io";
import { Link ,useNavigate} from 'react-router-dom';
import { format } from 'date-fns';
import Swal from "sweetalert2";

export default function Dashboard() {
    const [data1, setData] = useState([]);
    const randomNumber = localStorage.getItem("randomNumber")
    const navigate = useNavigate();
    useEffect(() => {
        if(!randomNumber){
          navigate("/");
        }
        
      }, []);
    useEffect(() => {
        fetchData1();
    }, []);
    const fetchData1 = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL}getTotalCounts`;
            const response = await axios.get(url);
            console.warn(response.data);
            setData(response.data);
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    };
    
    return (
        <div className="row">
            <div className="col-lg-3 col-sm-6">
            <Link to="/sports" className="stretched-link no-underline">
                <div className="card gradient-1">
                    <div className="card-body">
                        <h3 className="card-title text-white">Total Sports</h3>
                        <div className="d-inline-block">
                            <h2 className="text-white">{data1.sports}</h2>
                        </div>
                        <span className="float-right display-5 opacity-5">
                            <FaRunning />
                        </span>
                    </div>
                </div>
                </Link>
            </div>
            <div className="col-lg-3 col-sm-6">
            <Link to="/series" className="stretched-link no-underline">
                <div className="card gradient-2">
                    <div className="card-body">
                        <h3 className="card-title text-white">Total Series</h3>
                        <div className="d-inline-block">
                            <h2 className="text-white">{data1.series}</h2>
                        </div>
                        <span className="float-right display-5 opacity-5">
                            <FaTrophy />
                        </span>
                    </div>
                </div>
                </Link>
            </div>
            <div className="col-lg-3 col-sm-6">
            <Link to="/match" className="stretched-link no-underline">
                <div className="card gradient-3">
                    <div className="card-body">
                        <h3 className="card-title text-white">Total Live Match</h3>
                        <div className="d-inline-block">
                            <h2 className="text-white">{data1 && data1.matches &&  data1.matches.live}</h2>
                        </div>
                        <span className="float-right display-5 opacity-5">
                            <FaPlayCircle />
                        </span>
                    </div>
                </div>
                </Link>
            </div>
            <div className="col-lg-3 col-sm-6">
            <Link to="/match" className="stretched-link no-underline">
                <div className="card gradient-4">
                    <div className="card-body">
                        <h3 className="card-title text-white">Total Upcoming Match</h3>
                        <div className="d-inline-block">
                            <h2 className="text-white">{data1 && data1.matches &&  data1.matches.upcoming}</h2>
                        </div>
                        <span className="float-right display-5 opacity-5">
                            <FaCalendarAlt />
                        </span>
                    </div>
                </div>
                </Link>
            </div>
            <div className="col-lg-3 col-sm-6">
            <Link to="/match" className="stretched-link no-underline">
                <div className="card gradient-2">
                    <div className="card-body">
                        <h3 className="card-title text-white">Total Completed Match</h3>
                        <div className="d-inline-block">
                            <h2 className="text-white">{data1 && data1.matches &&  data1.matches.completed}</h2>
                        </div>
                        <span className="float-right display-5 opacity-5">
                            <FaTrophy />
                        </span>
                    </div>
                </div>
                </Link>
            </div>
            <div className="col-lg-3 col-sm-6">
            <Link to="/team" className="stretched-link no-underline">
                <div className="card gradient-4">
                    <div className="card-body">
                        <h3 className="card-title text-white">Total Teams</h3>
                        <div className="d-inline-block">
                            <h2 className="text-white">{data1.teams}</h2>
                        </div>
                        <span className="float-right display-5 opacity-5">
                            <FaUsers />
                        </span>
                    </div>
                </div>
                </Link>
            </div>
            <div className="col-lg-3 col-sm-6">
            <Link to="/player" className="stretched-link no-underline">
                <div className="card gradient-2">
                    <div className="card-body">
                        <h3 className="card-title text-white">Total Players</h3>
                        <div className="d-inline-block">
                            <h2 className="text-white">{data1.players}</h2>
                        </div>
                        <span className="float-right display-5 opacity-5">
                            <FaUser />
                        </span>
                    </div>
                </div>
                </Link>
            </div>
            <div className="col-lg-3 col-sm-6">
            <Link to="/fancy" className="stretched-link no-underline">
                <div className="card gradient-1">
                    <div className="card-body">
                        <h3 className="card-title text-white">Total Fancy</h3>
                        <div className="d-inline-block">
                            <h2 className="text-white">{data1.fancy}</h2>
                        </div>
                        <span className="float-right display-5 opacity-5">
                            <FaStar />
                        </span>
                    </div>
                </div>
                </Link>
            </div>
        </div>
    );
}
