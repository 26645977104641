import React, { useState,useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import './login.css';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
 const randomNumber = localStorage.getItem("randomNumber")
  const navigate = useNavigate();
  useEffect(() => {
    if(randomNumber){
      navigate("/Dashboard");
    }
    
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === 'admin@gmail.com' && password === '123456') {
      // Generate a random number
      const randomNumber = Math.floor(Math.random() * 1000000);
      
      // Store the random number in local storage
      localStorage.setItem('randomNumber', randomNumber);

      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Login successful!',
        text: 'Welcome to the admin panel.',
        timer: 1000,
      });

      setTimeout(() => {
        navigate("/Dashboard");
      }, 2000);
    } else {
      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Login failed!',
        text: 'Invalid credentials, please try again.',
      });
    }
  };

  return (
    <section className="login">
      <div className="login_box">
        <div className="left">
          <div className="top_link"></div>
          <div className="contact">
            <form onSubmit={handleLogin}>
              <h3>Admin</h3>
              <input
                type="text"
                placeholder="USERNAME"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="password"
                placeholder="PASSWORD"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit" className="submit">Login</button>
            </form>
          </div>
        </div>
        <div className="right">
          <div className="right-text">
            <h2>Welcome</h2>
          </div>
        </div>
      </div>
    </section>
  );
}
