
import React, { useState, useEffect } from "react";
import { Card, Table, Button, Form, Modal, Row, Col,Badge  } from "react-bootstrap";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEye } from "react-icons/fa6";
import { IoMdAdd } from "react-icons/io";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Swal from "sweetalert2";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import player from "../assets/image/playerimage.png";
import {FaRunning, FaTrophy, FaPlayCircle, FaCalendarAlt, FaUsers, FaUser, FaStar } from "react-icons/fa";

export default function Match() {
  const [data1, setData] = useState([]);
  const [DataTotal, setDataTotal] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [matchData, setData4] = useState([]);
  const [matches, setMatches] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedTeamsnew, setSelectedTeamsnew] = useState([]);
  const [selectedTeamsnew1, setSelectedTeamsnew1] = useState([]);
  const [selectedTeamsnew2, setSelectedTeamsnew2] = useState([]);
  const [showColumn_id, setShowColumn_id] = useState();
  const [showColumn_id1, setShowColumn_id1] = useState();
  const [showColumn_id2, setShowColumn_id2] = useState();
  const [showColumn_id3, setShowColumn_id3] = useState();
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedTeams1, setSelectedTeams1] = useState([]);
  const [selectedTeams2, setSelectedTeams2] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const [showplayerDeatails, setShowplayerDateails] = useState(false);
  const handleClosePlayer = () => setShowplayerDateails(false);
  const playerShowPlayer = () => setShowplayerDateails(true);
  const [showplayer, setShowplayer] = useState(false);
  const playerClose = () => setShowplayer(false);
  const playerShow = () => setShowplayer(true);


  useEffect(() => {
    fetchData1();
    fetchData2();
    fetchData3();
  }, []);

  const fetchData1 = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}matches`;
      const response = await axios.get(url);
      setDataTotal(response.data);
      setData(response.data.matches);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const getFilteredData = () => {
    return data1.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
  };

  const getPaginatedData = () => {
    const filteredData = getFilteredData();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(getFilteredData().length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const fetchData4 = async (_id) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}matches/${_id}`;
      const response = await axios.get(url);
      setData4(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const fetchData2 = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}teams`;
      const response = await axios.get(url);
      setData2(response.data.teams);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  const fetchData3 = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}getAllPlayers`;
      const response = await axios.get(url);
      setData3(response.data.players);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const Delete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const config = {
          method: "delete",
          url: `${process.env.REACT_APP_API_URL}matches/${id}`,
        };

        const response = await axios(config);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          timer: 1500,
        });

        fetchData1();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const updateMatchStatus = async (matchId, status) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!",
      });

      if (result.isConfirmed) {
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}updateMatchStatus`,
          data: {
            matchId,
            status
          }
        };

        const response = await axios(config);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          timer: 1500,
        });

        fetchData1(); // Refetch data after update
      }
    } catch (error) {
      console.error("Error updating match status:", error);
    }
  };
  
  const handleAddTeam = (_id) => {
    setShowColumn_id(_id);
    setSelectedTeamsnew([]);
  };
  const handleAddTeam1 = (_id,_id1,_id2) => {
    setShowColumn_id1(_id);
    setShowColumn_id2(_id1);
    setShowColumn_id3(_id2);
    console.warn(_id1,_id2)
    setSelectedTeamsnew([]);
  };
  const handleAddTeam2 = (_id,_id1,_id2) => {
    navigate("/UpadateRuns");
  localStorage.setItem("match_id",_id)
  localStorage.setItem("team1_id",_id1)
  localStorage.setItem("team2_id",_id2)

  };

  const AddTeamToMatch = async () => {
    const url = `${process.env.REACT_APP_API_URL}addTeamToMatch`;
    const requestBody = {
      matchId: showColumn_id,
      teamIds: selectedTeams,
    };

    try {
      const response = await axios.post(url, requestBody);
      Swal.fire({
        icon: "success",
        title: "Teams Added",
        text: "Your teams have been added successfully!",
      });
      handleClose();
      fetchData1();
    } catch (error) {
      console.error("Error adding teams:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Cannot add more than 2 teams to the match",
      });
      handleClose();
    }
  };
  const addPlayerToTeamInMatch = async () => {
    const url = `${process.env.REACT_APP_API_URL}addPlayerToTeamInMatch`;
    const requestBody = {
      matchId: showColumn_id1,
      team1Id: showColumn_id2,
      team2Id: showColumn_id3,
      playerId1s: selectedTeams1,
      playerId2s: selectedTeams2,
    };

    try {
      const response = await axios.post(url, requestBody);
    console.warn("response.data.error",)
      if(response.data.error){
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.error,
        });

      }else{
        Swal.fire({
          icon: "success",
          title: "Players Added",
          text: response.data.message,
        }); 
        playerClose();
        fetchData1();
      }
   
    } catch (error) {
      console.error("Error adding teams:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Cannot add more than 2 teams to the match",
      });
      playerClose();
    }
  };

  const options = data2.map((team) => ({
    value: team._id,
    label: team.name,
  }));
  const options1 = data3.map((team) => ({
    value: team._id,
    label: team.name,
  }));
  const options2 = data3.map((team) => ({
    value: team._id,
    label: team.name,
  }));

  const handleTeamSelectionnew = (selectedOptions) => {
    setSelectedTeamsnew(selectedOptions);
  
    const selectedValues = selectedOptions.map(option => option.value);
    setSelectedTeams(selectedValues);
  };
  const handleTeamSelectionnew1 = (selectedOptions) => {
    setSelectedTeamsnew1(selectedOptions);
  
    const selectedValues = selectedOptions.map(option => option.value);
    setSelectedTeams1(selectedValues);
  };
  const handleTeamSelectionnew2 = (selectedOptions) => {
    setSelectedTeamsnew2(selectedOptions);
  
    const selectedValues = selectedOptions.map(option => option.value);
    setSelectedTeams2(selectedValues);
  };
  const handleStatusChange = async (_id, Matchstatus) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change Match status!",
      });
  
      if (result.isConfirmed) {
        const requestBody = {
          _id: _id,
          Matchstatus: Matchstatus === 1 ? 0 : 1,
        };
  
        const response = await axios.post(`${process.env.REACT_APP_API_URL}MatchStatus`, requestBody);
        // const response = await axios.post(`${process.env.REACT_APP_API_URL}SportStatus`, requestBody);
  
        const data = response.data.status;
    console.warn("response.data",data)
        if (data === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            timer: 1500,
          });
  
          fetchData1();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            timer: 2000,
          });
        }
      }
    } catch (error) {
      console.error("Error changing status:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "There was an error changing the status.",
        timer: 2000,
      });
    }
  };
  const statusColor = (status) => {
    switch (status) {
      case 'live':
        return 'bg-danger text-white';
      case 'upcoming':
        return 'bg-success text-white';
      case 'complete':
        return 'bg-primary text-white';
      default:
        return 'bg-secondary text-white';
    }
  };

  return (
    <>
               <div className="row">
    <div className="col-lg-4 col-sm-6">
    <Link to="/match" className="stretched-link no-underline">
        <div className="card gradient-1">
            <div className="card-body">
                <h3 className="card-title text-white">Total </h3>
                <div className="d-inline-block">
                    <h2 className="text-white">{DataTotal.totalCount || 0}</h2>
                </div>
                <span className="float-right display-5 opacity-5">
                    <FaPlayCircle />
                </span>
            </div>
        </div>
        </Link>
    </div>
    <div className="col-lg-4 col-sm-6">
    <Link to="/match" className="stretched-link no-underline">
        <div className="card gradient-2">
            <div className="card-body">
                <h3 className="card-title text-white">Active </h3>
                <div className="d-inline-block">
                    <h2 className="text-white">{DataTotal.totalActive || 0}</h2>
                </div>
                <span className="float-right display-5 opacity-5">
                <FaPlayCircle />
                </span>
            </div>
        </div>
        </Link>
    </div>
    <div className="col-lg-4 col-sm-6">
    <Link to="/match" className="stretched-link no-underline">
        <div className="card gradient-3">
            <div className="card-body">
                <h3 className="card-title text-white"> InActive </h3>
                <div className="d-inline-block">
                    <h2 className="text-white">{DataTotal.totalInactive || 0}</h2>
                </div>
                <span className="float-right display-5 opacity-5">
                <FaPlayCircle />
                </span>
            </div>
        </div>
        </Link>
    </div>
    

</div>
    <div className="sports">
      <Card>
        <Card.Header className="header_bg d-flex justify-content-between">
          <Card.Title>Match List</Card.Title>
          <Link to="/addmatch"  className='text-dark'  style={{ textDecoration: 'none'}}  > <Button variant="success">   <IoMdAdd />Add New</Button></Link>
        
        </Card.Header>
        <Card.Body className="p-0">
        <div className="p-3 col-3">
            <Form.Control
              type="text"
              placeholder="Search Match..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="mb-3"
            />
          </div>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Name</th>
                  <th>Team1</th>
                  <th>Team2</th>
                  <th>Series Name</th>
                  {/* <th>Sports Name</th> */}
                  <th>Status</th>
                  <th>Match Status</th>
                  <th>Date Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data1 &&
                  getPaginatedData().map((data, index) => (
                    <tr key={index}>
                      <td>
                      <td><strong>{(currentPage - 1) * itemsPerPage + index + 1}</strong></td>
                      </td>
                      <td className='capitalize'>
                        <strong>{data.name}</strong>
                      </td>
                      <td className='capitalize'>
                        <strong>
                          {data.teams && data.teams[0]
                            ? data.teams[0].name
                            : "N/A"}
                        </strong>
                      </td>
                      <td className='capitalize'>
                        <strong>
                          {data.teams && data.teams[1]
                            ? data.teams[1].name
                            : "N/A"}
                        </strong>
                      </td>
                      <td>
                        <strong className='capitalize'>{data.series_Name}</strong>
                      </td>
                      {/* <td>
                        <strong>{data.sport_Name}</strong>
                      </td> */}
                      <td className="text-center">
                      <OverlayTrigger
                            key="Update2"
                            placement="top"
                            overlay={<Tooltip id={`Update2`}>Score Card</Tooltip>}
                          >
                        <Link to="/UpadateRuns"   onClick={() => handleAddTeam2(data._id,data.teams[0]._id,data.teams[1]._id)}>
                        <strong>  <Badge className={`status-badge ${statusColor(data.is_inplay)}`}>
                    {/* {data.is_inplay.charAt(0).toUpperCase() + data.is_inplay.slice(1)} */}
                    <FaEye className="fs-5"/>
                  </Badge></strong>
                  </Link>
                  </OverlayTrigger>
                  <div  className=" mt-1">
                  <select 
                  value={data.status} 
                  className="form-select"
                  onChange={(e) => updateMatchStatus(data._id, parseInt(e.target.value))}
                >
                  <option value={1}> Upcoming </option>
                  <option value={2}>  Live</option>
                  <option value={3}> Drink Break </option>
                  <option value={4}>Completed </option>
                </select>
                  </div>
                      </td>
                      <td className='capitalize'><strong>                   
                      <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch"
                 id={`flexSwitchCheckDefault-${data._id}`}
                 checked={data.Matchstatus === 1}
                 onChange={() => handleStatusChange(data._id, data.Matchstatus)}
             
                 />
              </div></strong></td>
                      <td>
                        <strong>
                          {format(
                            new Date(data.dateTime),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        </strong>
                      </td>
                      <td>
                        <div className="d-flex gap-2">
                          <OverlayTrigger
                            key="Update"
                            placement="top"
                            overlay={<Tooltip id={`Update`}>Add Team</Tooltip>}
                          >
                            <div onClick={handleShow}>
                              <Link
                                className="text-success"
                                onClick={() => handleAddTeam(data._id)}
                              >
                                <Badge className={`status-badge bg-success`}>

                                <IoMdAdd />
                                </Badge>
                              </Link>
                            </div>
                          </OverlayTrigger>

                          <OverlayTrigger
                            key="Player"
                            placement="top"
                            overlay={<Tooltip id={`Player`}>Add Players</Tooltip>}
                          >
                            <div onClick={playerShow}>
                              <Link
                                className="text-success"
                                onClick={() => handleAddTeam1(data._id,data.teams[0]._id,data.teams[1]._id)}
                              >
                                 <Badge className={`status-badge bg-warning`}>

                                <img src={player} width={15} height={15}/>
                                 </Badge>
                              </Link>
                            </div>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="Updated"
                            placement="top"
                            overlay={<Tooltip id={`Updated`}>View Playing Xl</Tooltip>}
                          >
                          <div onClick={playerShowPlayer}>
                          <Link  className="text-primary" onClick={() => fetchData4(data._id)}>
                          <Badge className={`status-badge bg-info`}>
                            <FaEye />
                            </Badge>
                          </Link>
                          </div>
                          </OverlayTrigger>
                          {/* <OverlayTrigger
                            key="Updated"
                            placement="top"
                            overlay={<Tooltip id={`Updated`}>Update Runs </Tooltip>}
                          >
                          <Link to="/UpadateRuns"  className="text-secondery"  onClick={() => handleAddTeam2(data._id,data.teams[0]._id,data.teams[1]._id)}>
                          <Badge className={`status-badge`}>
                            <FaEdit />
                          </Badge>
                          </Link>
                          </OverlayTrigger> */}
                          <Link
                            className="text-danger"
                            onClick={() => Delete(data._id)}
                          >
                             <Badge className={`status-badge bg-danger`}>

                            <RiDeleteBin6Line />
                             </Badge>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between p-3">
            <div>Showing {getPaginatedData().length} of {getFilteredData().length} results</div>
            <div>
              {Array.from({ length: totalPages }, (_, i) => (
                <Button
                  key={i}
                  onClick={() => handlePageChange(i + 1)}
                  disabled={i + 1 === currentPage}
                  className="me-2"
                >
                  {i + 1}
                </Button>
              ))}
            </div>
          </div>  
        </Card.Body>
      </Card>
      <Modal size="lg" show={showplayer} onHide={playerClose}>
        <Modal.Header closeButton className="bg-light">
          <Modal.Title>Add Player</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <Form>
            <div className="d-flex justify-content-between">
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="sport_id">
                    Team 1 <span className="login-danger">*</span>
                  </label>
                  <Select
                    id="sport_id"
                    isMulti
                    value={selectedTeamsnew1}
                    onChange={handleTeamSelectionnew1}
                    options={options1}
                  />

              
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="sport_id">
                    Team 2 <span className="login-danger">*</span>
                  </label>
                  <Select
                    id="sport_id"
                    isMulti
                    value={selectedTeamsnew2}
                    onChange={handleTeamSelectionnew2}
                    options={options1}
                  />
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Form.Group
            className="d-flex justify-content-end"
            controlId="exampleForm.ControlInput3"
          >
            <Button className="btn btn-success mx-2" onClick={addPlayerToTeamInMatch}>
              <span>Add Players</span>
            </Button>
          </Form.Group>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-light">
          <Modal.Title>Add Team</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <Form>
            <div className="d-flex justify-content-between">
              <div className="col-md-12 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="sport_id">
                    Select Teams <span className="login-danger">*</span>
                  </label>
                  <Select
                    id="sport_id"
                    isMulti
                    value={selectedTeamsnew}
                    onChange={handleTeamSelectionnew}
                    options={options}
                  />

                  
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Form.Group
            className="d-flex justify-content-end"
            controlId="exampleForm.ControlInput3"
          >
            <Button className="btn btn-success mx-2" onClick={AddTeamToMatch}>
              <span>Submit</span>
            </Button>
          </Form.Group>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" show={showplayerDeatails} onHide={handleClosePlayer}>
      <Modal.Header closeButton className="bg-light text-center">
        <Modal.Title className="text-center">Playing 11</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        {matchData && matchData.teams && matchData.teams.length > 0 ? (
          <Row>
            <Col md={6} className="mb-4">
              <h2>{matchData.teams[0].name}</h2>
              {matchData.teams[0].players && matchData.teams[0].players.length > 0 ? (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {matchData.teams[0].players.map((player, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{player.name}</td>
                        <td>{player.role}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>No players available for {matchData.teams[0].name}</p>
              )}
            </Col>
            <Col md={6}>
              <h2>{matchData.teams[1].name}</h2>
              {matchData.teams[1].players && matchData.teams[1].players.length > 0 ? (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {matchData.teams[1].players.map((player, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{player.name}</td>
                        <td>{player.role}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>No players available for {matchData.teams[1].name}</p>
              )}
            </Col>
          </Row>
        ) : (
          <p>No team data available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Form.Group className="d-flex justify-content-end" controlId="exampleForm.ControlInput3">
          <Button className="btn btn-success mx-2" onClick={handleClosePlayer}>
            <span>Close</span>
          </Button>
        </Form.Group>
      </Modal.Footer>
    </Modal>
    </div>
    </>
  );
}
