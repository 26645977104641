import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export default function Addsports() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData1();
  }, []);

  const fetchData1 = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}sports`;
      const response = await axios.get(url);
      console.warn(response.data.sports);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    role: "",
    image: null, // New field for image
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onFileChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const url = `${process.env.REACT_APP_Image_URL}createPlayer`;

      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("role", formData.role);
      if (formData.image) {
        formDataToSend.append("image", formData.image);
      }

      const response = await axios.post(url, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("API Response:", response.data);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Player added successfully.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/player");
          setFormData({
            name: "",
            role: "",
            image: null, // Reset image field
          });
        }
      });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to add Player. Please try again later.",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <Row className="page-titles mx-0">
        <Col className="p-md-0">
          <Breadcrumb>
            <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Add Player</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Container fluid>
        <div className="card">
          <div className="card-body">
            <div className="form-validation">
              <Form className="form-valide" onSubmit={handleSubmit}>
                <Row>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="name">
                        Player Name <span className="login-danger">*</span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        placeholder="Enter Player Name"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="role">
                        Role <span className="login-danger"></span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="role"
                        name="role"
                        value={formData.role}
                        placeholder="Enter Role"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="image">
                        Upload Image <span className="login-danger"></span>
                      </label>
                      <input
                        className={`form-control `}
                        type="file"
                        id="image"
                        name="image"
                        onChange={onFileChange}
                      />
                    </div>
                  </div>
                  <Col md={12} className="d-flex justify-content-end mt-3">
                    <Button type="submit" className="btn btn-primary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
