import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function AddMatch() {
  const navigate = useNavigate();
  const [sportsData, setSportsData] = useState(null);
  const [seriesData, setSeriesData] = useState(null);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    fetchSportsData();
    fetchSeriesData();
  }, []);

  const fetchSportsData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}sports`;
      const response = await axios.get(url);
      setSportsData(response.data.sports);
    } catch (error) {
      console.error("Error fetching sports:", error);
    }
  };

  const fetchSeriesData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}series`;
      const response = await axios.get(url);
      setSeriesData(response.data);
    } catch (error) {
      console.error("Error fetching series:", error);
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    sport_id: "",
    series_id: "",
    status: "1",
    dateTime: "",
    marketCount: "",
    competitionRegion: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    setFormData({
      ...formData,
      dateTime: date.getTime().toString(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const url = `${process.env.REACT_APP_API_URL}matches`;

      const requestData = {
        name: formData.name,
        sport_id: formData.sport_id,
        series_id: formData.series_id,
        status: formData.status,
        dateTime: formData.dateTime,
        marketCount: formData.marketCount,
        competitionRegion: formData.competitionRegion,
      };

      const response = await axios.post(url, requestData);
      console.log("API Response:", response.data);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Match added successfully.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/match");

          setFormData({
            name: "",
            sport_id: "",
            series_id: "",
            status: "1",
            dateTime: "",
            marketCount: "",
            competitionRegion: "",
          });
          setStartDate(new Date());
        }
      });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to add match. Please try again later.",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <Row className="page-titles mx-0">
        <Col className="p-md-0">
          <Breadcrumb>
            <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Add Match</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Container fluid>
        <div className="card">
          <div className="card-body">
            <div className="form-validation">
              <Form className="form-valide" onSubmit={handleSubmit}>
                <Row>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="sport_id">
                        Sports <span className="login-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        id="sport_id"
                        name="sport_id"
                        value={formData.sport_id}
                        onChange={onInputChange}
                      >
                        <option value="">Select Sports</option>
                        {sportsData && sportsData.map((sport) => (
                          <option key={sport._id} value={sport._id}>
                            {sport.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="series_id">
                        Series Name <span className="login-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        id="series_id"
                        name="series_id"
                        value={formData.series_id}
                        onChange={onInputChange}
                      >
                        <option value="">Select Series</option>
                        {seriesData && seriesData.map((series) => (
                          <option key={series._id} value={series._id}>
                            {series.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="name">
                        Match Name <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="marketCount">
                        Market Count <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="marketCount"
                        name="marketCount"
                        value={formData.marketCount}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="competitionRegion">
                        Competition Region <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="competitionRegion"
                        name="competitionRegion"
                        value={formData.competitionRegion}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="dateTime">
                        Date & Time <span className="login-danger">*</span>
                      </label>
                      <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                      />
                    </div>
                  </div>
                 
                  
                  <Col md={12} className="d-flex justify-content-end mt-3">
                    <Button type="submit" className="btn btn-primary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
