import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export default function Addsports() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    sportscode: "",
    slug: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const url = `${process.env.REACT_APP_API_URL}sports`;

      const requestData = {
        name: formData.name,
        sportCode: formData.sportscode,
        slug: formData.slug,
      };

      const response = await axios.post(url, requestData);
      console.log("API Response:", response.data);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Sports added successfully.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/sports");

          setFormData({
            name: "",
            sportscode: "",
            slug: "",
          });
        }
      });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to add sports. Please try again later.",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
     
      });
    }
  };

  return (
    <>
      <Row className="page-titles mx-0">
        <Col className="p-md-0">
          <Breadcrumb>
            <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Add Sports</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Container fluid>
        <div className="card">
          <div className="card-body">
            <div className="form-validation">
              <Form className="form-valide" onSubmit={handleSubmit}>
                <Row>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="name">
                        Sports Name <span className="login-danger">*</span>
                      </label>
                      <input
                        className={`form-control `}
                        id="name"
                        name="name"
                        type="text"
                        value={formData.name}
                        placeholder="Enter Sports Name"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="sportscode">
                        Sports Code <span className="login-danger"></span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="sportscode"
                        name="sportscode"
                        value={formData.sportscode}
                        placeholder="Enter Sports Code"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 form_details mt-3">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="slug">
                        Slug <span className="login-danger"></span>
                      </label>
                      <input
                        className={`form-control `}
                        type="text"
                        id="slug"
                        name="slug"
                        value={formData.slug}
                        placeholder="Enter Slug"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <Col md={12} className="d-flex justify-content-end mt-3">
                    <Button type="submit" className="btn btn-primary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
